<template>
  <div>
    <!-- <div>
        <button @click="printEditorHtml">print html</button>
        <button @click="getEditorText">print text</button>
      </div> -->
    <div style="border: 1px solid #ccc; margin: 10px 0">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <Editor style="height: 400px; overflow-y: hidden" :defaultConfig="editorConfig" v-model="ruleFormProp.description"
        @onChange="onChange" @onCreated="onCreated" />
    </div>
    <!-- <div style="margin-top: 10px">
        <textarea
          v-model="html"
          readonly
          style="width: 100%; height: 200px; outline: none"
        ></textarea>
      </div> -->
  </div>
</template>
  
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  name: "MyEditor",
  components: { Editor, Toolbar },
  props: {
    ruleFormProp: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      editor: null,
      // html: "<p>hello&nbsp;world</p>",
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
        toolbarKeys: [
          // 菜单 key
          'headerSelect',

          // 分割线
          '|',

          // 菜单 key
          // 'bold', 'italic',
          'bold',

          // 菜单组，包含多个菜单
          // {
          //   key: 'group-more-style', // 必填，要以 group 开头
          //   title: '更多样式', // 必填
          //   iconSvg: '<svg>....</svg>', // 可选
          //   menuKeys: ["through", "code", "clearStyle"] // 下级菜单 key ，必填
          // },
          // 继续配置其他菜单...
        ]
      },
      editorConfig: {
        placeholder: "",
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {},
      },
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      this.changeDisabled()
    },
    onChange(editor) {
      console.log("onChange", editor.getHtml()); // onChange 时获取编辑器最新内容
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor;
      if (editor == null) return;

      console.log(editor.getHtml()); // 执行 editor API
    },
    changeDisabled() {
      if (this.disabled) {
        this.editor.disable() // 禁用编辑器
      } else {
        this.editor.enable() // 启用编辑器
      }
    }
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = "<p>Ajax 异步设置内容 HTML</p>";
    }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
  watch: {
    disabled(newValue) {
      // 处理属性变化
      this.disabled = newValue
      if (this.disabled == false) {
        this.editor.enable() // 启用编辑器
      }
    }
  }
};
</script>
  
<style src="@wangeditor/editor/dist/css/style.css"></style>
  