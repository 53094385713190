import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 物料分页查询
 */
export function getMaterielPage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/page',
        method: 'get',
        params
    })
}

/**
 * 创建物料
 */
export function createMateriel(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新物料
 */
export function updateMateriel(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除物料
 */
export function deleteMateriel(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/delete',
        method: 'delete',
        data: params
    })
}

/**
 * 物料信息
 * @param params
 */
export function getMaterielById(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/get/'+params.materielId,
        method: 'get',
    })
}

/**
 * 导出物料
 * @param params
 */
export function exportMateriel(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/materiel/export',
        method: 'post',
        data: params
    })
}

/**
 * 下载物料模版
 */
export function downMaterielTemplate() {
    downTemplate("ruge.materiel", envInfo.bgApp.basePath);
}

/**
 * 下载企业黄页模版
 */
export function downEnterpriseTemplate() {
    downTemplate("ruge.customerCompany", envInfo.bgApp.customerjwPath);
}

/**
 * 下载公海池模版
 */
export function downHighseasTemplate() {
    downTemplate("ruge.customer", envInfo.bgApp.personnelmanagePath);
}

/**
 * 下载客户列表模版
 */
export function downCustomerListTemplate() {
    downTemplate("ruge.customerOpp", envInfo.bgApp.personnelmanagePath);
}

/**
 * 下载线索列表模版
 */
export function downCustomerLeadsTemplate() {
    downTemplate("ruge.customerLeads", envInfo.bgApp.personnelmanagePath);
}
