<template>
  <div class="user-evaluation-component app-container">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addAlmanac">{{
          this.$t("commons.addNew")
        }}</el-button>
        <el-button icon="el-icon-upload" size="small" @click="downEnterpriseTemplate">{{ $t("commons.importTemplate")
        }}</el-button>
        <el-button icon="el-icon-upload2" size="small" @click="uploadModel = true">{{
          $t("commons.import") }}</el-button>
        <el-button icon="el-icon-download" size="small" @click="exportHandler">{{
          $t("commons.export") }}</el-button>
        <el-button @click="getListData" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
      </span>
      <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show" :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
          ">
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
    </div>
    <!-- 新增dialog -->
    <el-dialog :visible.sync="serviceObj.dialogVisible" :before-close="() => {
      serviceObj.dialogVisible = false;
    }" width="728px" :destroy-on-close="true" :close-on-click-modal="false">
      <template slot="title">
        <span>{{ serviceObj.dialogTitle }}</span>
      </template>
      <serviceDialog v-if="serviceObj.dialogVisible" :dialogStatus="serviceObj.dialogStatus" @close="handleClose"
        :id="serviceObj.id"></serviceDialog>
    </el-dialog>
    <!--导入文件-->
    <el-dialog :visible.sync="uploadModel" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import serviceDialog from "./serviceDialog.vue";
import {
  downEnterpriseTemplate,
} from "@/api/business/base/tenant/materiel";
import {
  getRecordList,
  deleteAlmanacList,
  exportAlmanacList
} from "@/api/ruge/jwPark/serviceRecord";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
export default {
  components: {
    finalTable,
    serviceDialog,
    Upload
  },
  data() {
    return {
      uploadModel: false,
      uploadType: "ExcelImport",
      rootPath: envInfo.bgApp.customerjwPath,
      excelType: "ruge.customerCompany",
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "companyName", label: "企业名称", width: "" },
          { prop: "companyCode", label: "企业编码", width: "" },
          { prop: "companyContacts", label: "企业联系人", width: "" },
          { prop: "companyPhones", label: "企业电话", width: "" },
          { prop: "companyLocation", label: "所在位置", width: "" },
          { prop: "createdBy", label: "创建人", width: "" },
          { prop: "creationDate", label: "创建时间 ", width: "500" },
          { prop: "operation", label: "操作", width: "120" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          companyName: {
            type: "input",
            label: "企业名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          companyCode: {
            type: "input",
            label: "企业编码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          companyContacts: {
            type: "input",
            label: "企业联系人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          companyPhones: {
            type: "input",
            label: "企业电话",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          companyLocation: {
            type: "input",
            label: "所在位置",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          createdBy: {
            type: "input",
            label: "创建人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入",
            prefixIcon: "el-icon-search",
          },
          creationDate: {
            type: "dateRange",
              actionType: "goSearch",
              value: [null, null],
          },
          // operation: {
          //   type: "button",
          //   filterCount: "",
          //   actionType: "clickEvent",
          //   eventName: "showMoreFilters",
          //   label: "更多筛选",
          // },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "查看",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "删除",
              },
            ],
          },
          creationDate: {
              type: "dateFormat",
            },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      serviceObj: {
        tableLoading: false,
        dialogVisible: false,
        dialogStatus: "add",
        dialogTitle: "添加企业",
        listQuery: {
          current: 1,
          rowCount: 10,
          noticeTitle: null,
          noticeType: null,
          startCreationDate: null,
          endCreationDate: null,
        },
      },
    }
  },
  created() {
    const curSearch = sessionStorage.getItem("evaluation_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.serviceObj.listQuery = { ...this.serviceObj.listQuery, ...tempObj };
        Object.keys(this.serviceObj.listQuery).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value = this.serviceObj.listQuery[item];
          }
        });
        this.dataset.searchLineConfig.creationDate.value = [
          tempObj.startCreationDate,
          tempObj.endCreationDate,
        ];
      }
    this.getListData();
  },
  methods: {
    getListData() {
      this.serviceObj.tableLoading = true;
      const params = { ...this.serviceObj.listQuery };
      for (let pn in params) {
        !params[pn] && params[pn] !== 0 && (params[pn] = null);
      }
      getRecordList(params).then(res => {
        this.dataset.tableData = res.data.rows;
        this.dataset.pageVO.total = res.data.total;
      })
        .finally(() => {
          this.serviceObj.tableLoading = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.creationDate && datas.params.creationDate.length > 0) {
            this.serviceObj.listQuery.startCreationDate = datas.params.creationDate[0]
              ? new Date(datas.params.creationDate[0]).getTime()
              : null;
            this.serviceObj.listQuery.endCreationDate = datas.params.creationDate[1]
              ? new Date(datas.params.creationDate[1]).getTime()
              : null;
            delete datas.params.creationDate;
          }
        this.serviceObj.listQuery = {
          ...this.serviceObj.listQuery,
          ...datas.params,
        };
        this.serviceObj.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.getListData();
      } else if (datas.type === "paginationChange") {
        this.serviceObj.listQuery.current = datas.params.current.page;
        this.serviceObj.listQuery.rowCount = datas.params.current.limit;
        this.getListData();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.serviceObj.dialogTitle = "编辑企业";
            this.serviceObj.id = datas.row.id;
            this.serviceObj.dialogStatus = "edit";
            this.serviceObj.dialogVisible = true;
            break;
          case "view":
            this.serviceObj.dialogTitle = "企业详情";
            this.serviceObj.id = datas.row.id;
            this.serviceObj.dialogStatus = "view";
            this.serviceObj.dialogVisible = true;
            break;
          case "delete":
            this.singleDeleteHandler(datas.row);
            break;
        }
      }
    },
    addAlmanac() {
      this.serviceObj.id = null;
      this.serviceObj.dialogStatus = "add";
      this.serviceObj.dialogTitle = "添加企业";
      this.serviceObj.dialogVisible = true
    },
    handleClose(freshFlag) {
      this.serviceObj.dialogVisible = false;
      freshFlag && this.getListData();
    },
    singleDeleteHandler(row) {
      this.deleteHandler(row.id);
    },
    deleteHandler(ids) {
      this.$confirm(
        "删除后将不可恢复，确定删除么？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteAlmanacList({ id: ids }).then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功！");
              this.serviceObj.listQuery.current = 1;
              this.dataset.pageVO.current = 1;
              this.getListData();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    downEnterpriseTemplate() {
      downEnterpriseTemplate();
    },
    // 导出
    exportHandler() {
      exportAlmanacList(this.serviceObj.listQuery).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "企业黄页",
          taskStatus: 0,
          rootPath: "gcustomerjw",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    uploadSuccess() {
      this.uploadModel = false;
      // this.$message({
      //   type: "success",
      //   message: this.$t("message.uploadSuccess"),
      // });
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
  },
  watch: {
    serviceObj: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.tableLoading);
      },
      deep: true,
    },
  },
}

</script>

<style lang="less" scoped>
.user-evaluation-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
    }

    .icon-loudoutu {
      font-size: 12px;
    }

    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }

    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
}
</style>
