import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 企业黄页 - 分页查询
 * @param params
 */
export function getRecordList(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/company/getCompanyByParam',
      method: 'get',
      params,
    });
  }

  /**
 * 企业黄页 - 新增
 * @param params
 */
export function addAlmanacList(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/company/addCompany',
    method: 'post',
    data: params,
  });
}

/**
 * 企业黄页 - 查看详情
 * @param params
 */
export function getAlmanacDetailById(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/company/selectById',
    method: 'get',
    params,
  });
}

/**
 * 企业黄页 - 删除
 * @param params
 */
export function deleteAlmanacList(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/company/deleteCompany',
    method: 'get',
    params,
  });
}

/**
 * 企业黄页 - 导出
 * @param params
 */
export function exportAlmanacList(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/company/export',
    method: 'get',
    params,
  });
}