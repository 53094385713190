<template>
    <div class="park-notice-dialog">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="企业名称" prop="companyName">
                        <el-input maxlength="50" placeholder="请输入内容" @input="setCode()" :disabled="currentState === 'view'"
                            v-model="ruleForm.companyName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="企业编码" prop="companyCode">
                        <el-input maxlength="50" placeholder="" disabled v-model="ruleForm.companyCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="企业联系人" prop="companyContacts">
                        <el-input maxlength="50" placeholder="请输入内容" :disabled="currentState === 'view'"
                            v-model="ruleForm.companyContacts"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="企业电话" prop="companyPhones">
                        <el-input maxlength="50" placeholder="请输入内容" :disabled="currentState === 'view'"
                            v-model="ruleForm.companyPhones"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所在位置" prop="companyLocation">
                        <el-input maxlength="50" placeholder="请输入内容" :disabled="currentState === 'view'"
                            v-model="ruleForm.companyLocation"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="企业简介">
                        <my-editor :ruleFormProp="ruleForm" :disabled="currentState === 'view'"  ref="childRef" ></my-editor>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item align="right" v-if="currentState === 'edit' || currentState === 'add'">
                <el-button @click="resetForm()">取消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
            <el-form-item align="right" v-if="currentState === 'view'">
                <el-button @click="changeStateToEdit" type="primary">编辑</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import MyEditor from '@/components/Editor/wangEdit';
import {
    addAlmanacList,
    getAlmanacDetailById
} from "@/api/ruge/jwPark/serviceRecord";
import pinyin from "js-pinyin";
export default {
    name: "serviceDialog",
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
    },
    components: {
        MyEditor
    },
    data() {
        return {
            ruleForm: {
                companyName: null,
                companyCode: null,
                companyContacts: null,
                companyPhones: null,
                companyLocation: null,
                description: null,
            },
            rules: {
                companyName: [
                    { required: true, message: "企业名称为必填项", trigger: "blur" },
                    { min: 1, max: 24, message: "企业名称长度应大于1个字符", trigger: "blur" }
                ],
                // companyPhones: [
                //     { pattern: /^1[0-9]{10}$/, message: "请输入有效的手机号码", trigger: "blur" }
                // ]
            },
            currentState: "add",
        }
    },
    methods: {
        getAlmanacDetail(id) {
            getAlmanacDetailById({id}).then(res => {
                console.log(res,'res');
                this.ruleForm.companyName = res.data.companyName
                this.ruleForm.companyCode = res.data.companyCode
                this.ruleForm.companyContacts = res.data.companyContacts
                this.ruleForm.companyPhones = res.data.companyPhones
                this.ruleForm.companyLocation = res.data.companyLocation
                this.ruleForm.description = res.data.description
            })
        },
        resetForm() {
            this.$refs["ruleForm"].resetFields();
            this.$emit("close", false);
        },
        submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitHandler();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      submitHandler() {
        const params = { ...this.ruleForm };
        console.log(params,'params');
        if (this.currentState === "add") {
          addAlmanacList(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$emit("close", true);
            } else {
              this.$message.warning("新增失败！");
            }
          });
        } else if (this.currentState === "edit") {
          params.id = this.id;
          addAlmanacList(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.$emit("close", true);
            } else {
              this.$message.warning("新增失败！");
            }
          });
        }
      },
      changeStateToEdit() {
        this.currentState = "edit";
        this.$refs.childRef.changeDisabled()
      },
      setCode() {
        let name = this.ruleForm.companyName
        this.ruleForm.companyCode = pinyin.getCamelChars(name);
      }
    },
    watch: {
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
        id: {
            handler(id) {
                if (!id) return;
                this.getAlmanacDetail(id);
            },
            immediate: true,
        },
    },
}
</script>
<style scoped lang="less">
.park-notice-dialog {
    .upload-container {
        display: flex;

        .tips-container {
            margin-left: 15px;
            font-size: 12px;

            &>div {
                height: 20px;
                line-height: 20px;
            }

            .delete-icon {
                position: absolute;
                bottom: 0;
                font-size: 14px;
                color: #ff0000;
                cursor: pointer;
            }
        }
    }

    .avatar-uploader {
        border: 1px dotted #ccc;
        height: 178px;
        width: 178px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>